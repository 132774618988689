@import url("https://fonts.googleapis.com/css2?family=Asap:wght@400;700&display=swap");
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Asap', 'sans-serif';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #00325c;
}
.with-bg {
  background-color: #00325c;
  background-image: url("../images/bg_small.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 77px);
  margin: 0;
  padding: 0;
}
@media (min-width: 30em) {
  .with-bg {
    background-image: url("../images/bg_big.jpg");
  }
}
.svealogo {
  flex-shrink: 0;
  width: 90px;
}
button:hover svg path {
  stroke: #fff;
}
button:hover.fillicon svg path {
  fill: #fff;
  stroke: transparent;
}
button:hover.blackhover,
a:hover.blackhover {
  color: #000;
}
button:hover.blackhover svg path,
a:hover.blackhover svg path {
  stroke: #000;
}
button:disabled svg path {
  stroke: #a0aec0;
}
button:disabled.filled svg path {
  stroke: #fff;
}
button:disabled.fillicon svg path {
  fill: #a0aec0;
  stroke: transparent;
}
button:disabled.fillicon.filled svg path {
  fill: #fff;
}
button:focus,
button:active {
  box-shadow: none;
}
.card {
  transition: all 250ms;
  background: #f6f6f1;
}
.card .active:hover {
  background: #e1e1d5;
  transition: all 250ms;
}
.card .disabled > div {
  opacity: 0.5;
}
.card .disabled .badgewrap {
  opacity: 1;
}
.card .disabled .badgewrap > div {
  background: #c53030;
}
input.PhoneInputInput {
  width: 100%;
  min-width: 0;
  outline: 0;
  position: relative;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: all 0.2s;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  border-radius: 2px;
  border: 1px solid;
  border-color: #434343;
  background: inherit;
  border-width: 1px;
  margin-bottom: 0.25rem;
}
input.PhoneInputInput:hover {
  border-color: #718096;
}
input.PhoneInputInput::-webkit-input-placeholder {
  color: #718096;
}
input.PhoneInputInput:-ms-input-placeholder {
  color: #718096;
}
input.PhoneInputInput::placeholder {
  color: #718096;
}
input.PhoneInputInput:focus {
  z-index: 1;
  border-color: #00325c;
  box-shadow: 0 0 0 1px #00325c;
}
input.PhoneInputInput[data-invalid=invalid] {
  border-color: #c53030;
  box-shadow: 0 0 0 1px #c53030;
}
input.PhoneInputInput[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}
.badgewrap .DISABLED {
  background: #c53030;
}
.FROZEN,
.DISABLED,
.DISABLED-ADMIN {
  background: #c53030;
}
.ACTIVE {
  background: #2f855a;
}
.WAITING_APPROVAL {
  background: #00325c;
}
.request:last-child {
  border-bottom: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.disabled a {
  color: #000;
}
.disabled label {
  color: #999;
}
.breadcrumbitems li:last-child a {
  color: #000;
}
.waiting .formitem:hover input,
.waiting .formitem:hover select {
  color: #000;
  opacity: 1;
}
.waiting input[disabled],
.waiting select[disabled] {
  opacity: 1;
  border: none;
  padding-left: 0;
}
.waiting label {
  color: #00325c;
  font-weight: bold;
}
.waiting .chakra-select__icon-wrapper {
  display: none;
}
.waiting .PhoneInputCountrySelect {
  display: none;
}
@media (min-width: 30em) {
  .form .formitem:first-child {
    padding-right: 12px;
  }
  .form .formitem:last-child {
    padding-left: 12px;
  }
  .form .formitem:nth-child(2) {
    padding-right: 12px;
    padding-left: 12px;
  }
}
.approval-group .approval-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 60px;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.approval-group .chakra-select__wrapper {
  position: initial;
  width: 0;
}
.approval-group .chakra-select__icon-wrapper {
  left: 40px;
  top: 22px;
}
.approval-group .chakra-input__left-addon {
  width: 70px;
  background: none;
  border: 0;
}
